// extracted by mini-css-extract-plugin
export var noMargin = "l_pL d_cb";
export var carouselCol = "l_pM d_cC";
export var carouselWrapper = "l_k7 d_k7 d_by d_bC d_bN d_v d_G";
export var carouselImageWrapper = "l_k8 d_k8 d_G d_v";
export var carouselContainer = "l_pN d_cC";
export var carouselContainerFull = "l_pP d_dS";
export var carouselImg = "l_mP d_k9 d_v d_G d_bQ";
export var imageTextWrapperLeft = "l_k2 d_k2 d_k1 d_Z d_bS";
export var imageTextWrapperCenter = "l_k3 d_k3 d_k1 d_Z d_bS";
export var imageTextWrapperRight = "l_k4 d_k4 d_k1 d_Z d_bS";
export var imageTextWrapperHidden = "l_k0 d_k0";
export var imageAltSmall = "l_pQ d_k5";
export var imageAltNormal = "l_pR d_k5";
export var imageAltLarge = "l_pS d_k5";
export var imageTextSmall = "l_pT d_k6";
export var imageTextNormal = "l_pV d_k6";
export var imageTextLarge = "l_pW d_k6";