// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_rC d_bC";
export var storyRowWrapper = "t_hx d_hx d_bJ";
export var storyLeftWrapper = "t_rD d_by d_bN";
export var storyWrapperFull = "t_rF d_cC";
export var storyWrapperFullLeft = "t_nN d_cC d_by d_bN d_bC";
export var contentWrapper = "t_pb d_hy";
export var storyLeftWrapperCenter = "t_rG d_hz d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "t_rH d_hF";
export var storyHeader = "t_rJ d_hD d_v d_cr";
export var storyHeaderCenter = "t_hC d_hC d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "t_hB d_hB d_bx d_dv";
export var storyBtnWrapper = "t_rK d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "t_hJ d_hJ d_d0 d_v d_by d_bC";
export var imageWrapper = "t_rL d_ff d_Y";
export var imageWrapperFull = "t_rM d_v d_G d_bd d_Y";