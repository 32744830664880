// extracted by mini-css-extract-plugin
export var carouselContainer = "C_pN d_v d_G d_bd d_Y";
export var carouselContainerCards = "C_vB C_pN d_v d_G d_bd d_Y";
export var carouselContainerSides = "C_vC d_v d_G d_Y";
export var prevCarouselItem = "C_vD d_v d_G d_Z d_j";
export var prevCarouselItemL = "C_vF C_vD d_v d_G d_Z d_j";
export var moveInFromLeft = "C_vG";
export var prevCarouselItemR = "C_vH C_vD d_v d_G d_Z d_j";
export var moveInFromRight = "C_vJ";
export var selectedCarouselItem = "C_vK d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "C_vL C_vK d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "C_vM C_vK d_v d_G d_Y d_bd";
export var nextCarouselItem = "C_vN d_v d_G d_Z d_j";
export var nextCarouselItemL = "C_vP C_vN d_v d_G d_Z d_j";
export var nextCarouselItemR = "C_vQ C_vN d_v d_G d_Z d_j";
export var arrowContainer = "C_vR d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "C_vS C_vR d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "C_vT C_vS C_vR d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "C_vV C_vR d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "C_vW";
export var nextArrowContainerHidden = "C_vX C_vV C_vR d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "C_kH d_Z";
export var prevArrow = "C_vY C_kH d_Z";
export var nextArrow = "C_vZ C_kH d_Z";
export var carouselIndicatorContainer = "C_v0 d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "C_v1 d_v d_by d_bD";
export var carouselText = "C_v2 d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "C_v3 C_v2 d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "C_v4 d_b6";
export var carouselIndicator = "C_v5 C_v4 d_b6";
export var carouselIndicatorSelected = "C_v6 C_v4 d_b6";
export var arrowsContainerTopRight = "C_v7 d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "C_v8 d_Z d_bk d_bB";
export var arrowsContainerSides = "C_v9 d_Z d_bk d_bB";
export var smallArrowsBase = "C_wb d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "C_wc C_wb d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "C_wd C_wc C_wb d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "C_wf C_wb d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "C_wg C_wf C_wb d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "C_pD";
export var iconSmall = "C_wh";
export var multipleWrapper = "C_wj d_bB d_bD d_bd";
export var multipleImage = "C_wk d_bB";
export var sidesPrevContainer = "C_wl C_wc C_wb d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "C_wm C_wc C_wb d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";