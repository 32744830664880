// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_qK d_fn d_bF d_dt";
export var alignLeft = "r_pX d_fn d_bF d_dt";
export var alignDiscCenter = "r_qL d_fp d_bC d_dv";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignDiscRight = "r_qM d_fq d_bG d_dw";
export var alignRight = "r_pY d_fq d_bG d_dw";
export var footerContainer = "r_qN d_dV d_bV";
export var footerContainerFull = "r_qP d_dS d_bV";
export var footerHeader = "r_kf d_kf";
export var footerTextWrapper = "r_qQ d_v";
export var footerDisclaimerWrapper = "r_km d_km d_cj";
export var badgeWrapper = "r_qR d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "r_qS undefined";
export var footerDisclaimerLeft = "r_qT undefined";
export var verticalTop = "r_qV d_by d_bH d_bM d_bK";
export var firstWide = "r_qW";
export var disclaimer = "r_qX d_by d_bH";
export var socialDisclaimer = "r_qY";
export var left = "r_qZ";
export var wide = "r_q0 d_cx";
export var right = "r_q1 d_bJ";
export var line = "r_fj d_fk d_ct";
export var badgeDisclaimer = "r_q2 d_bB d_bN d_bH";
export var badgeContainer = "r_q3 d_by d_bG d_bN";
export var badge = "r_q4";
export var padding = "r_q5 d_c6";
export var end = "r_q6 d_bG";
export var linkWrapper = "r_q7 d_dz";
export var link = "r_mC d_dz";
export var colWrapper = "r_q8 d_cw";
export var consent = "r_f d_f d_bB d_bN";
export var media = "r_q9 d_bw d_dx";
export var itemRight = "r_rb";
export var itemLeft = "r_rc";
export var itemCenter = "r_rd";
export var exceptionWeight = "r_rf x_sj";